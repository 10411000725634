<template>
  <div>
    <!-- <pre>data: {{ data }}</pre> -->
    <!-- <pre>dataCleared: {{ dataCleared }}</pre> -->
    <!-- <pre>field: {{ field }}</pre> -->
    <!-- <pre>selectedRow: {{ selectedRow }}</pre> -->

    <b-table-simple hover small bordered class="table-view">
      <b-tbody>
        <b-tr v-for="(row, rowIndex) in dataCleared" :key="rowIndex" @click="clickRow(rowIndex)"
          :class="{ 'selected-row': rowIndex === selectedRow }">
          <b-td>{{ rowIndex }}</b-td>
          <b-td>{{ row }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => null,
    },
    field: {
      type: String,
      default: "",
    },
  },
  components: {},
  data() {
    return {
      selectedRow: "",
    };
  },

  created() {
    this.setActiveRow();
  },
  computed: {
    dataCleared() {
      const res = this.data
      delete res[''] // cat last field with key ''

      return res
    },
  },
  methods: {
    clickRow(rowIndex) {
      this.selectedRow = rowIndex;
      this.$emit("selected", this.selectedRow);
    },
    setActiveRow() {
      if (!this.field) {
        return false;
      } else {
        this.selectedRow = this.field;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-view tr {
  &:hover {
    box-shadow: inset 0 0 2px 3px rgba(#3bafda, 0.25);
    cursor: pointer;
  }

  &.selected-row {
    background: rgba(#3bafda, 0.25);
    box-shadow: none;
  }
}
</style>
