<template>
  <div class="col-10 mx-auto">
    <div v-if="loading" class="text-center">
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>

    <template v-else>
      <hr class="mt-0" />
      <!-- <pre>sourceTypes: {{ sourceTypes }}</pre> -->
      <!-- <pre>metric: {{ metric }}</pre> -->
      <!-- <pre>cached: {{ cached }}</pre> -->
      <!-- <pre>form: {{ form }}</pre> -->

      <h4 class="text-center mb-3">
        {{ $t("form.settings-for-metric") }}: {{ metric.name }}
        <a @click.prevent="$emit('on-cancel')" href="#">[x]</a>
      </h4>

      <b-alert :variant="msg.type" dismissible class="mt-3" v-model="msg.has" :show="msg.text">{{ msg.text }}</b-alert>

      <form @submit.prevent="handleSubmit" novalidate class="form-horizontal">
        <!-- type  -->
        <div class="form-group row">
          <label for="form-type" class="col-sm-3 col-form-label required">{{
            $t("form.source-type")
          }}</label>
          <div class="col-sm-9">
            <Multiselect v-model="form.type" :options="sourceTypes" track-by="id" label="title" :multiple="false"
              :allow-empty="false" :placeholder="$t('form.source-type-placeholder')" :maxHeight="240" :class="{
                'is-invalid': submitted && $v.form.type.$error,
              }" />
            <div v-if="submitted && $v.form.type.$error" class="invalid-feedback">
              <div v-if="!$v.form.type.required">
                {{ $t("form.source-type-req") }}
              </div>
            </div>
          </div>
        </div>

        <!-- url (source) -->
        <div class="form-group row">
          <label for="form-url" class="col-sm-3 col-form-label required">{{
            $t("form.source-address")
          }}</label>
          <div class="col-sm-9">
            <div class="input-group">
              <input class="form-control" v-model="form.url" id="form-url"
                :placeholder="$t('form.source-address-placeholder')"
                :disabled="inprogressUploadData || form.type.id === 5" :class="[
                  {
                    'is-invalid': submitted && $v.form.url.$error,
                  },
                ]" />
              <div class="input-group-append">
                <button @click.prevent="uploadSourceData" class="btn btn-primary" type="button"
                  :disabled="inprogressUploadData">
                  {{ $t("form.upload-data") }}
                </button>
              </div>
              <div v-if="submitted && $v.form.url.$error" class="invalid-feedback">
                <span v-if="!$v.form.url.required">{{
                  $t("form.source-address-req")
                }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- field -->
        <div class="form-group row">
          <label for="form-field" class="col-sm-3 col-form-label required">{{
            $t("form.monitored-param")
          }}</label>
          <div class="col-sm-9">
            <input class="form-control" v-model="formField" id="form-field"
              :placeholder="$t('form.monitored-param-placeholder')" readonly :class="[
                {
                  'is-invalid': submitted && $v.form.field.$error,
                },
              ]" />
            <div v-if="submitted && $v.form.field.$error" class="invalid-feedback">
              <span v-if="!$v.form.field.required">{{
                $t("form.monitored-param-req")
              }}</span>
            </div>
          </div>
        </div>

        <!-- uploadData -->
        <div v-if="uploadData === 'load'" class="text-center">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>
        <div class="form-group row" v-if="uploadData && uploadData !== 'load'">
          <!-- (1) google table -->
          <div v-if="
            form.type.id === 1 &&
            cached.type.id === 1 &&
            uploadData.sourceData.length
          " class="gtable-viewer-wrapper">
            <TableViewer :data="uploadData.sourceData" :field="form.field" @selected="tableCellSelect" />
          </div>

          <!-- (2) docs_type (google-docx) -->
          <div v-if="
            form.type.id === 2 &&
            cached.type.id === 2 &&
            uploadData.sourceData
          " class="gtable-viewer-wrapper">
            <DocsViewer :data="uploadData.sourceData" :field="form.field" @selected="docRowSelect" />
          </div>

          <!-- (3) json  -->
          <div v-if="
            form.type.id === 3 &&
            cached.type.id === 3 &&
            uploadData.sourceData
          " class="json-viewer-wrapper">
            <JsonViewer :value="uploadData.sourceData" @keyclick="jsonKeyclick" :expand-depth="5" expanded />
          </div>

          <!-- (5) gps  -->
          <div v-if="
            form.type.id === 5 &&
            cached.type.id === 5 &&
            uploadData.sourceData
          " class="gps-viewer-wrapper">
            <GpsViewer :data="uploadData.sourceData" :field="form.field" @selected="gpsSelect" />
          </div>
        </div>

        <!-- button -->
        <div class="mt-4 text-center text-lg-right">
          <div class="form-group mb-0">
            <button :disabled="inprogress" @click.prevent="$emit('on-cancel')" class="btn btn-warning mx-2 mb-2">
              {{ $t("btn.cancel") }}
            </button>

            <button :disabled="inprogress" class="btn btn-primary mx-2 mb-2 mr-lg-0" type="submit">
              {{ $t("btn.save") }}
            </button>
          </div>
        </div>
      </form>

      <hr />
    </template>

    <b-modal ref="modal-login-gps" hide-header hide-footer centered>
      <h4 class="text-center">{{ $t("modal.login-gps-service") }}</h4>
      <FormLoginGps @logged="gpsLoggedIn" @cancel="gpsCancelLoggedIn" />
    </b-modal>

  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import JsonViewer from "vue-json-viewer";
import "vue-json-viewer/style.css";
import TableViewer from "@/components/Table-viewer";
import DocsViewer from "@/components/Docs-viewer";
import GpsViewer from "@/components/Gps-viewer";
import FormLoginGps from "@/components/Form-login-gps";

export default {
  props: {
    metric: {
      type: Object,
      default: () => { },
    },
  },
  components: {
    Multiselect,
    JsonViewer,
    TableViewer,
    DocsViewer,
    GpsViewer,
    FormLoginGps,
  },
  data() {
    return {
      loading: true,
      submitted: false,
      inprogress: false,
      uploadData: "",
      inprogressUploadData: false,
      msg: {
        has: false,
        type: "",
        text: "",
      },
      form: {
        type: "",
        url: "",
        field: "",
      },
      cached: {
        type: "",
        url: "",
      },
    };
  },
  validations() {
    return {
      form: this.formRules,
    };
  },
  created() {
    this.loadData();
  },
  computed: {
    ...mapGetters(["sourceTypes"]),

    formRules() {
      let rules = {};

      rules.type = {
        required,
      };

      rules.url = {
        required,
      };

      rules.field = {
        required,
      };

      return rules;
    },
    formField() {
      console.log('this.form: ', this.form);

      if (this.cached.type.id === this.form.type.id) {

        // if g table - normilize + 1
        if (this.form.field && this.form.type.id === 1) {
          const cell = this.form.field.split(":");
          return `${Number(cell[0]) + 1}:${Number(cell[1]) + 1}`;
        }

        return this.form.field;
      } else {
        return "";
      }
    },
  },
  methods: {
    async loadData() {
      // set init metric value of: type, url, field
      this.form.type =
        this.sourceTypes.find((t) => t.id === this.metric.type) ||
        this.sourceTypes[0];
      this.form.url = this.metric.url || "";
      this.form.field = this.metric.field || "";

      this.cached.type = this.form.type;
      this.cached.url = this.form.url;

      this.loading = false;
    },
    async handleSubmit() {
      this.submitted = true;
      this.msg = {
        has: false,
        type: "",
        text: "",
      };

      this.$v.$touch();

      if (!this.$v.form.$invalid) {
        this.$emit("on-update", {
          ...this.metric,
          url: this.cached.url,
          type: this.cached.type.id,
          field: this.form.field,
        });
      }

    },
    async uploadSourceData() {
      this.inprogressUploadData = true;
      this.uploadData = "load";
      // this.form.field = ""; // ### clear field on get data

      this.msg = {
        has: false,
        type: "",
        text: "",
      };

      const formData = new FormData();

      // form
      formData.append("type", this.form.type.id);

      // if source type gps (id:5) url not required atall 
      if (this.form.type.id !== 5) {
        formData.append("url", this.form.url);
      } else {
        this.form.url = "gps tracker";
      }

      // Display the key/value pairs ###debug
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + " :", pair[1]);
      // }

      try {
        const response = await axios.post(
          this.$urls.URL_SOURCE_GET_DATA,
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        );

        this.checkParseData(response.data);
      } catch (error) {
        console.log("Error: ", error);
        this.msg.has = true;
        this.msg.type = "danger";
        this.msg.text =
          error.response?.data?.message ||
          "Error on try upload data from source";
        // this.msg.text =
        //   error.response?.data?.message || this.$t("template.msg-add-error");

        this.uploadData = "";

        if (error.response.data.errors) {
          for (const [key, value] of Object.entries(
            error.response.data.errors
          )) {
            this.msg.text += `[${key}: ${value}] `;
          }
        }

        return false;
      } finally {
        this.inprogressUploadData = false;
        this.$scrollToTop();
      }
    },
    checkParseData(data) {
      console.log("checkParseData, data: ", data);
      // check types and parsed data
      this.msg.has = false;

      if (this.cached.type.id !== this.form.type.id) {
        this.form.field = "";
      }

      if (data.sourceData) {
        // check type 1 - google-doc - sourceData = array
        if (this.form.type.id === 1) {
          if (Array.isArray(data.sourceData)) {
            this.uploadData = data;

            // cached type and url from last success req
            this.cached.type = this.form.type;
            this.cached.url = this.form.url;
          } else {
            this.uploadData = "";
            this.msg.has = true;
            this.msg.type = "danger";
            this.msg.text = "Parse google-doc data!";
          }
        }

        // check type 2 - docs - sourceData = object
        if (this.form.type.id === 2) {
          // console.log('data: ', data)

          if (data.success) {
            this.uploadData = data;

            // cached type and url from last success req
            this.cached.type = this.form.type;
            this.cached.url = this.form.url;
          } else {
            this.uploadData = "";
            this.msg.has = true;
            this.msg.type = "danger";
            this.msg.text =
              data.sourceData.message || "Error on get DOCS uploaded data!";
          }
        }

        // check type 3 - json - sourceData = object
        if (this.form.type.id === 3) {
          if (data.sourceData?.success) {
            this.uploadData = data;

            // cached type and url from last success req
            this.cached.type = this.form.type;
            this.cached.url = this.form.url;
          } else {
            this.uploadData = "";
            this.msg.has = true;
            this.msg.type = "danger";
            this.msg.text =
              data.sourceData.message || "Error on get JSON uploaded data!";
          }
        }

        // check type 4
        // if (this.form.type.id === 4) {

        // }

        // check type 5 - gps
        if (this.form.type.id === 5) {
          // if (data.sourceData?.success) {

          if (!data.sourceData?.cars?.children) {
            this.$refs['modal-login-gps'].show()
          } else {
            this.uploadData = data;

            // cached type and url from last success req
            this.cached.type = this.form.type;
            this.cached.url = this.form.url;
          }

          // } else {
          //   this.uploadData = "";
          //   this.msg.has = true;
          //   this.msg.type = "danger";
          //   this.msg.text =
          //     data.sourceData.message || "Error on get JSON uploaded data!";
          // }
        }

      } else {
        this.uploadData = "";
        this.msg.has = true;
        this.msg.type = "danger";
        this.msg.text = "Error on get uploaded data!";
      }
    },
    jsonKeyclick(node) {
      this.form.field = node.slice(2);
    },
    tableCellSelect(row, cell) {
      this.form.field = `${row}:${cell}`;
    },
    docRowSelect(row) {
      this.form.field = row;
    },
    gpsSelect(gps = null) {
      // field: { "param": "dist", "real_id": "3259" }
      if (gps) {
        try {
          this.form.field = JSON.stringify(gps);
        } catch (e) {
          this.form.field = "";
        }
      } else {
        this.form.field = "";
      }
    },
    gpsLoggedIn() {
      this.$refs['modal-login-gps'].hide();
      this.uploadSourceData();
    },
    gpsCancelLoggedIn() {
      this.$refs['modal-login-gps'].hide();
      this.uploadData = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.gtable-viewer-wrapper {
  max-height: 500px;
  width: 100%;
  overflow: auto;
  border: 1px solid #eee;
  border-radius: 6px;
}

.json-viewer-wrapper {
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 6px;
}

.gps-viewer-wrapper {
  width: 100%;
}
</style>
