var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"form-horizontal",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-alert',{staticClass:"mt-3",attrs:{"variant":_vm.msg.type,"dismissible":"","show":_vm.msg.text},model:{value:(_vm.msg.has),callback:function ($$v) {_vm.$set(_vm.msg, "has", $$v)},expression:"msg.has"}},[_vm._v(_vm._s(_vm.msg.text))]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-3 col-form-label required",attrs:{"for":"form-gps-login"}},[_vm._v(_vm._s(_vm.$t("form.login")))]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.login),expression:"form.login"}],staticClass:"form-control",class:[
          {
            'is-invalid': _vm.submitted && _vm.$v.form.login.$error,
          },
        ],attrs:{"id":"form-gps-login","name":"form-gps-login","placeholder":_vm.$t('form.login-placeholder')},domProps:{"value":(_vm.form.login)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "login", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.login.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.login.required)?_c('span',[_vm._v(_vm._s(_vm.$t("form.login-req")))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-3 col-form-label required",attrs:{"for":"form-gps-password"}},[_vm._v(_vm._s(_vm.$t("form.password")))]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control",class:[
          {
            'is-invalid': _vm.submitted && _vm.$v.form.password.$error,
          },
        ],attrs:{"type":"password","id":"form-gps-password","name":"form-gps-password","placeholder":_vm.$t('form.password-placeholder')},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "password", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.password.required)?_c('span',[_vm._v(_vm._s(_vm.$t("form.password-req")))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"mt-4 text-center text-lg-right"},[_c('div',{staticClass:"form-group mb-0"},[_c('button',{staticClass:"btn btn-warning mx-2 mb-2",attrs:{"disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.$emit('cancel')}}},[_vm._v(" "+_vm._s(_vm.$t("btn.cancel"))+" ")]),_c('button',{staticClass:"btn btn-primary mx-2 mb-2 mr-lg-0",attrs:{"disabled":_vm.loading,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("btn.enter"))+" ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }