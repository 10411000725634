var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-10 mx-auto"},[(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Text Centered"}})],1):[_c('hr',{staticClass:"mt-0"}),_c('h4',{staticClass:"text-center mb-3"},[_vm._v(" "+_vm._s(_vm.$t("form.settings-for-metric"))+": "+_vm._s(_vm.metric.name)+" "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('on-cancel')}}},[_vm._v("[x]")])]),_c('b-alert',{staticClass:"mt-3",attrs:{"variant":_vm.msg.type,"dismissible":"","show":_vm.msg.text},model:{value:(_vm.msg.has),callback:function ($$v) {_vm.$set(_vm.msg, "has", $$v)},expression:"msg.has"}},[_vm._v(_vm._s(_vm.msg.text))]),_c('form',{staticClass:"form-horizontal",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-3 col-form-label required",attrs:{"for":"form-type"}},[_vm._v(_vm._s(_vm.$t("form.source-type")))]),_c('div',{staticClass:"col-sm-9"},[_c('Multiselect',{class:{
              'is-invalid': _vm.submitted && _vm.$v.form.type.$error,
            },attrs:{"options":_vm.sourceTypes,"track-by":"id","label":"title","multiple":false,"allow-empty":false,"placeholder":_vm.$t('form.source-type-placeholder'),"maxHeight":240},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),(_vm.submitted && _vm.$v.form.type.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.type.required)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("form.source-type-req"))+" ")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-3 col-form-label required",attrs:{"for":"form-url"}},[_vm._v(_vm._s(_vm.$t("form.source-address")))]),_c('div',{staticClass:"col-sm-9"},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.url),expression:"form.url"}],staticClass:"form-control",class:[
                {
                  'is-invalid': _vm.submitted && _vm.$v.form.url.$error,
                },
              ],attrs:{"id":"form-url","placeholder":_vm.$t('form.source-address-placeholder'),"disabled":_vm.inprogressUploadData || _vm.form.type.id === 5},domProps:{"value":(_vm.form.url)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "url", $event.target.value)}}}),_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","disabled":_vm.inprogressUploadData},on:{"click":function($event){$event.preventDefault();return _vm.uploadSourceData.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("form.upload-data"))+" ")])]),(_vm.submitted && _vm.$v.form.url.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.url.required)?_c('span',[_vm._v(_vm._s(_vm.$t("form.source-address-req")))]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-3 col-form-label required",attrs:{"for":"form-field"}},[_vm._v(_vm._s(_vm.$t("form.monitored-param")))]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formField),expression:"formField"}],staticClass:"form-control",class:[
              {
                'is-invalid': _vm.submitted && _vm.$v.form.field.$error,
              },
            ],attrs:{"id":"form-field","placeholder":_vm.$t('form.monitored-param-placeholder'),"readonly":""},domProps:{"value":(_vm.formField)},on:{"input":function($event){if($event.target.composing)return;_vm.formField=$event.target.value}}}),(_vm.submitted && _vm.$v.form.field.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.field.required)?_c('span',[_vm._v(_vm._s(_vm.$t("form.monitored-param-req")))]):_vm._e()]):_vm._e()])]),(_vm.uploadData === 'load')?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Text Centered"}})],1):_vm._e(),(_vm.uploadData && _vm.uploadData !== 'load')?_c('div',{staticClass:"form-group row"},[(
          _vm.form.type.id === 1 &&
          _vm.cached.type.id === 1 &&
          _vm.uploadData.sourceData.length
        )?_c('div',{staticClass:"gtable-viewer-wrapper"},[_c('TableViewer',{attrs:{"data":_vm.uploadData.sourceData,"field":_vm.form.field},on:{"selected":_vm.tableCellSelect}})],1):_vm._e(),(
          _vm.form.type.id === 2 &&
          _vm.cached.type.id === 2 &&
          _vm.uploadData.sourceData
        )?_c('div',{staticClass:"gtable-viewer-wrapper"},[_c('DocsViewer',{attrs:{"data":_vm.uploadData.sourceData,"field":_vm.form.field},on:{"selected":_vm.docRowSelect}})],1):_vm._e(),(
          _vm.form.type.id === 3 &&
          _vm.cached.type.id === 3 &&
          _vm.uploadData.sourceData
        )?_c('div',{staticClass:"json-viewer-wrapper"},[_c('JsonViewer',{attrs:{"value":_vm.uploadData.sourceData,"expand-depth":5,"expanded":""},on:{"keyclick":_vm.jsonKeyclick}})],1):_vm._e(),(
          _vm.form.type.id === 5 &&
          _vm.cached.type.id === 5 &&
          _vm.uploadData.sourceData
        )?_c('div',{staticClass:"gps-viewer-wrapper"},[_c('GpsViewer',{attrs:{"data":_vm.uploadData.sourceData,"field":_vm.form.field},on:{"selected":_vm.gpsSelect}})],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"mt-4 text-center text-lg-right"},[_c('div',{staticClass:"form-group mb-0"},[_c('button',{staticClass:"btn btn-warning mx-2 mb-2",attrs:{"disabled":_vm.inprogress},on:{"click":function($event){$event.preventDefault();return _vm.$emit('on-cancel')}}},[_vm._v(" "+_vm._s(_vm.$t("btn.cancel"))+" ")]),_c('button',{staticClass:"btn btn-primary mx-2 mb-2 mr-lg-0",attrs:{"disabled":_vm.inprogress,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("btn.save"))+" ")])])])]),_c('hr')],_c('b-modal',{ref:"modal-login-gps",attrs:{"hide-header":"","hide-footer":"","centered":""}},[_c('h4',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("modal.login-gps-service")))]),_c('FormLoginGps',{on:{"logged":_vm.gpsLoggedIn,"cancel":_vm.gpsCancelLoggedIn}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }