<template>
  <div>
    <div v-if="standalone" class="row">
      <div class="col-12">
        <h2 class="text-center">
          <template v-if="template && template.name" class="text-center mb-3">
            {{ template.name }} (id: {{ template.id }})
          </template>
        </h2>
        <p class="text-muted text-center mb-3">
          {{ $t("wizard.step-3-notice") }}
        </p>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-12">
        <h2 class="text-center">
          3. {{ $t("wizard.step-3-title") }}:
          <template v-if="template && template.name" class="text-center mb-3">
            {{ template.name }}
          </template>
          [
          <template v-if="model.category && model.category.name">
            {{ model.category.name }}
            <template v-if="model.subCategory && model.subCategory.name">
              / {{ model.subCategory.name }}
            </template>
          </template>
          ]
        </h2>
        <p class="text-muted text-center mb-3">
          {{ $t("wizard.step-3-notice") }}
        </p>
      </div>
    </div>

    <slot name="alert"></slot>

    <div v-if="loading" class="card-body">
      <div class="text-center">
        <b-spinner variant="primary"></b-spinner>
      </div>
    </div>
    <div v-else>
      <div v-if="metrics.length && !showMetricform" class="mb-0">
        <b-table
          :items="metrics"
          :fields="tableFields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :tbody-tr-class="rowClass"
          small
        >
          <template #head(name)>
            {{ $t("table.name") }}
          </template>

          <template #head(type)>
            {{ $t("table.type") }}
          </template>

          <template #head(described)>
            {{ $t("table.description") }}
          </template>

          <template #head(source)>
            {{ $t("table.source") }}
          </template>

          <template #head(status)>
            {{ $t("table.status") }}
          </template>

          <template #head(field)>
            {{ $t("table.field") }}
          </template>

          <!-- id -->
          <template #cell(id)="data">
            {{ data.item.id + 1 }}
          </template>

          <!-- field -->
          <template #cell(field)="data">
            {{ normilizeMetricField(data.item) }}
          </template>

          <!-- source -->
          <template #cell(source)="data">
            <div class="button-list text-nowrap">
              <b-button
                variant="outline-success"
                size="xs"
                class="btn-source"
                @click="sourceSetting(data.item)"
              >
                {{ $t("wizard.btn-setup") }}
              </b-button>
            </div>
          </template>

          <!-- type -->
          <template #cell(type)="data">
            {{ typeToStr(data.item.type) }}
          </template>

          <!-- status -->
          <template #cell(status)="data">
            <b-form-checkbox
              switch
              :key="data.item.id"
              :checked="Boolean(data.item.status)"
              @change="toggleMetric(data.item)"
            />
          </template>
        </b-table>
      </div>

      <SourceForm
        v-if="showMetricform"
        :metric="selectedMetric"
        @on-update="onSetSource"
        @on-cancel="
          showMetricform = false;
          $scrollToTop();
        "
      />

      <!-- <hr /> -->
      <!-- <pre>metrics: {{ metrics }}</pre> -->
      <!-- <pre>id: {{ id }}</pre> -->
      <!-- <pre>template: {{ template }}</pre> -->
    </div>

    <!-- <pre>id: {{ this.id }}</pre> -->
    <!-- <pre>model: {{ model }}</pre> -->
    <!-- <pre>model.template: {{ model.template }}</pre> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import SourceForm from "@/components/Source-form";

export default {
  components: {
    SourceForm,
  },
  props: {
    id: {
      type: Number,
    },
    model: {
      type: Object,
      default: () => {},
    },
    standalone: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      template: "",
      metrics: [],
      sortBy: "id",
      sortDesc: false,
      tableFields: [
        {
          key: "id",
          label: "#",
          class: "cell-id",
          sortable: true,
        },
        {
          key: "name",
          sortable: true,
        },
        {
          key: "type",
          sortable: true,
        },
        {
          key: "described",
          sortable: true,
        },
        {
          key: "field",
        },
        {
          key: "source",
          class: "cell-source",
        },
        {
          key: "status",
          class: "cell-status",
        },
      ],
      showMetricform: false,
      selectedMetric: "",
    };
  },
  created() {
    this.loadData();
  },
  computed: {
    ...mapGetters(["sourceTypes"]),
  },
  methods: {
    async loadData() {
      this.loading = true;

      try {
        await Promise.all([this.$store.dispatch("fetchSourceTypes")]);

        if (this.standalone) {
          this.loadTemplate(this.id);
        }

        this.loading = false;
      } catch (error) {
        console.log("loadData Error: ", error);
      }
    },
    async loadTemplate() {
      this.loading = true;

      try {
        const response = await axios.get(
          this.$urls.URL_USER_TEMPLATES + `/show/${this.id}`
        );
        // console.log("loadTemplate, response: ", response);
        this.template = response.data;
      } catch (error) {
        console.log("loadTemplate, error: ", error);
      } finally {
        this.showMetricform = false;
        this.loading = false;
      }
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      return item.status ? "" : "row-disabled-metric";
    },
    toggleMetric(metric) {
      // console.log("toggleMetric, metric: ", metric);
      this.metrics.find((m) => m.id === metric.id).status = Number(
        !metric.status
      );

      this.$emit("on-update", this.metrics, this.template);
    },
    sourceSetting(metric) {
      // console.log("sourceSetting, metric: ", metric);
      this.selectedMetric = metric;
      this.showMetricform = true;
      this.$emit("on-hide-alert");
    },
    onSetSource(metric) {
      // console.log("step-3, onSetSource, metric: ", metric);
      this.showMetricform = false;
      this.metrics[this.metrics.findIndex((m) => m.id === metric.id)] = {
        ...metric,
      };

      this.$emit("on-update", this.metrics, this.template);
      this.$scrollToTop();
    },
    typeToStr(id = null) {
      return this.sourceTypes.filter((t) => t.id === id)[0]?.title || " - ";
    },
    normilizeMetricField(metric) {
      if (metric && metric.field) {
        // if g table - normilize + 1
        if (metric.type === 1) {
          const cell = metric.field.split(":");
          return `${Number(cell[0]) + 1}:${Number(cell[1]) + 1}`;
        }

        return metric.field;
      }
    },
  },
  watch: {
    id() {
      if (this.id !== 0) {
        this.loadTemplate(this.id);
      }
    },
    template() {
      // add id === index
      this.metrics = this.template.metrics.map((m, i) => {
        return {
          id: i,
          ...m,
        };
      });

      this.$emit("on-update", this.metrics, this.template);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .cell-source {
    width: 200px;
    text-align: center;
  }
  .cell-status {
    width: 120px;
    text-align: center;
  }
  .row-disabled-metric {
    opacity: 0.5;

    .btn-source {
      pointer-events: none;
    }
  }
}
</style>
