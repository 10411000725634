<template>
  <div>
    <!-- <pre>maxCell: {{maxCell}}</pre> -->
    <!-- <pre>create table from this data: {{data}}</pre> -->
    <!-- <pre>field: {{field}}</pre> -->
    <!-- <pre>selectedRow: {{ selectedRow }}</pre> -->
    <!-- <pre>selectedCol: {{ selectedCol }}</pre> -->

    <b-table-simple hover small bordered class="table-view">
      <b-tbody>
        <b-tr v-for="(row, rowIndex) in data" :key="rowIndex">
          <b-td
            v-for="colIndex in maxCell"
            :key="colIndex"
            @click="clickCell(rowIndex, colIndex)"
            :class="selectedClass(rowIndex, colIndex)"
          >
            {{ row[colIndex - 1] }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    field: {
      type: String,
      default: "",
    },
  },
  components: {},
  data() {
    return {
      selectedRow: "",
      selectedCol: "",
    };
  },

  created() {
    this.setActiveCell();
  },
  computed: {
    maxCell() {
      return Math.max.apply(
        null,
        this.data.map((a) => a.length)
      );
    },
  },
  methods: {
    clickCell(rowIndex, colIndex) {
      this.selectedRow = ++rowIndex;
      this.selectedCol = colIndex;

      this.$emit("selected", this.selectedRow - 1, this.selectedCol - 1);
    },
    selectedClass(rowIndex, colIndex) {
      return this.selectedRow === ++rowIndex && this.selectedCol === colIndex
        ? "selected-cell"
        : "";
    },
    setActiveCell() {
      if (!this.field) {
        return false;
      } else {
        const cell = this.field.split(":");

        this.selectedRow = Number(cell[0]) + 1;
        this.selectedCol = Number(cell[1]) + 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-view td {
  &:hover {
    box-shadow: inset 0 0 2px 3px rgba(#3bafda, 0.25);
    cursor: pointer;
  }

  &.selected-cell {
    background: rgba(#3bafda, 0.25);
    box-shadow: none;
  }
}
</style>
