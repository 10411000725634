<template>
  <div>

    <!-- <pre>field: {{ field }}</pre> -->
    <!-- <pre>gps: {{ gps }}</pre> -->
    <!-- <pre>cars: {{ cars }}</pre> -->
    <!-- <pre>data: {{ data }}</pre> -->

    <h4 class="text-center">{{ $t('gps-source.select-car') }}</h4>
    <div class="table-scroll-wrapper mb-4">
      <b-table-simple hover small bordered caption-top responsive class="table-view mb-0">
        <!-- <b-thead>
        <b-tr>
          <b-th>ID</b-th>
          <b-th>Name</b-th>
        </b-tr>
      </b-thead> -->
        <b-tbody>
          <b-tr v-for="car in cars" :key="car.real_id" @click="clickCarRow(car)" :class="selectedCarClass(car)">
            <b-td>{{ car.real_id }}</b-td>
            <b-td>{{ car.name }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>

    <h4 class="text-center">{{ $t('gps-source.select-param') }}</h4>
    <div class="table-scroll-wrapper">
      <b-table-simple hover small bordered caption-top responsive class="table-view mb-0">
        <!-- <b-thead>
        <b-tr>
          <b-th>Name</b-th>
          <b-th>Value</b-th>
        </b-tr>
      </b-thead> -->
        <b-tbody>
          <b-tr v-for="(value, name) in data.params" :key="name" @click="clickParamRow(name, value)"
            :class="selectedParamClass(name)">
            <b-td>{{ name }}</b-td>
            <b-td>{{ value }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => null,
    },
    field: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      gps: {
        param: "",
        real_id: "",
      }
    };
  },

  created() {
    this.initData();
  },
  computed: {
    cars() {
      if (this.data?.cars?.children?.length && this.data.cars.children[0].children?.length) {
        return this.data.cars.children[0].children
      }

      return [];
    },
  },
  methods: {
    initData() {
      if (this.field) {
        try {
          this.gps = JSON.parse(this.field);
        } catch (e) {
          this.$emit("selected", null);
        }
      }
    },
    clickParamRow(name, value) {
      this.gps.param = name;

      if (this.gps.param && this.gps.real_id) {
        this.$emit("selected", this.gps);
      } else {
        this.$emit("selected", null);
      }
    },
    clickCarRow(car) {
      this.gps.real_id = car.real_id;

      if (this.gps.param && this.gps.real_id) {
        this.$emit("selected", this.gps);
      } else {
        this.$emit("selected", null);
      }
    },
    selectedParamClass(name) {
      return this.gps.param === name
        ? "selected-tr"
        : "";
    },
    selectedCarClass(car) {
      return this.gps.real_id === car.real_id
        ? "selected-tr"
        : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.table-view tr {
  &:hover {
    box-shadow: inset 0 0 2px 3px rgba(#3bafda, 0.25);
    cursor: pointer;
  }

  &.selected-tr {
    background: rgba(#3bafda, 0.25);
    box-shadow: none;
  }
}

// lg +
@media (min-width: 992px) {
  .table-scroll-wrapper {
    max-height: 234px;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    border: 1px solid #eee;
    border-left-width: 0;
    border-right-width: 0;
  }
}
</style>
