<template>
  <form @submit.prevent="handleSubmit" novalidate class="form-horizontal">

    <b-alert :variant="msg.type" dismissible class="mt-3" v-model="msg.has" :show="msg.text">{{ msg.text }}</b-alert>

    <!-- login -->
    <div class="form-group row">
      <label for="form-gps-login" class="col-sm-3 col-form-label required">{{ $t("form.login") }}</label>
      <div class="col-sm-9">
        <input class="form-control" v-model="form.login" id="form-gps-login" name="form-gps-login"
          :placeholder="$t('form.login-placeholder')" :class="[
            {
              'is-invalid': submitted && $v.form.login.$error,
            },
          ]" />
        <div v-if="submitted && $v.form.login.$error" class="invalid-feedback">
          <span v-if="!$v.form.login.required">{{
            $t("form.login-req")
          }}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="form-gps-password" class="col-sm-3 col-form-label required">{{ $t("form.password") }}</label>
      <div class="col-sm-9">
        <input type="password" class="form-control" v-model="form.password" id="form-gps-password"
          name="form-gps-password" :placeholder="$t('form.password-placeholder')" :class="[
            {
              'is-invalid': submitted && $v.form.password.$error,
            },
          ]" />
        <div v-if="submitted && $v.form.password.$error" class="invalid-feedback">
          <span v-if="!$v.form.password.required">{{
            $t("form.password-req")
          }}</span>
        </div>
      </div>
    </div>

    <!-- button -->
    <div class="mt-4 text-center text-lg-right">
      <div class="form-group mb-0">
        <button :disabled="loading" @click.prevent="$emit('cancel')" class="btn btn-warning mx-2 mb-2">
              {{ $t("btn.cancel") }}
            </button>
        <button :disabled="loading" class="btn btn-primary mx-2 mb-2 mr-lg-0" type="submit">
          {{ $t("btn.enter") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      loading: false,
      submitted: false,

      msg: {
        has: false,
        type: "",
        text: "",
      },
      form: {
        login: "",
        password: "",
      },
    };
  },
  validations() {
    return {
      form: this.formRules,
    };
  },
  computed: {
    formRules() {
      let rules = {};

      rules.login = {
        required,
      };

      rules.password = {
        required,
      };

      return rules;
    },
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      this.submitted = true;

      this.msg = {
        has: false,
        type: "",
        text: "",
      };

      this.$v.$touch();

      if (!this.$v.form.$invalid) {
        const formData = new FormData();

        // form
        formData.append("login", this.form.login);
        formData.append("password", this.form.password);

        // Display the key/value pairs ###debug
        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + " :", pair[1]);
        // }

        try {
          const response = await axios.post(
            this.$urls.URL_LOGIN_GPS,
            formData,
            {
              headers: {
                "content-type": "multipart/form-data",
              },
            }
          );

          this.$emit("logged");

        } catch (error) {
          console.log("Error: ", error);
          this.msg.has = true;
          this.msg.type = "danger";
          this.msg.text =
            error.response?.data?.message ||
            "Error on try login gps form";

          if (error.response.data.errors) {
            for (const [key, value] of Object.entries(
              error.response.data.errors
            )) {
              this.msg.text += `[${key}: ${value}] `;
            }
          }

          return false;
        } finally {
          this.loading = false;
        }

      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
